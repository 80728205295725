<template>
  <div>
    <div class="text-right pb-2"></div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-start py-1">
        <b-col md="8" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">All Classes</h3>
          <p class="font-weight-bold text-colorGrey">
            Your all Classes display here.
          </p>
        </b-col>
        <b-col md="4" class="px-3 py-1 text-right">
          <b-button
            type="submit"
            variant="colorBlue"
            rounded
            class="align-items-center"
            v-b-toggle.create-class-program-sidebar
          >
            <feather-icon icon="PlusIcon" class="mr-50 text-colorWhite" />
            <span class="text-colorWhite">Add Class</span>
          </b-button>
        </b-col>
      </b-row>
      <b-card class="mx-2" style="border-radius: 20px">
        <div>
          <b-input-group class="mb-2" style="width: 400px; float: right;">
            <b-form-input
              v-model="filter"
              placeholder="Search..."
            ></b-form-input>
            <b-input-group-append>
              <b-button variant="primary" @click="clearSearch">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <b-table
          stacked="md"
          :fields="fields"
          :items="classes"
          thead-class="tableHeadColor"
          small
          rounded
          :filter="filter"
          :filter-function="filterTable"
        >
          <template #head()="data">
            <div class="py-50">
              <span class="font-weight-bolder text-colorBlack">{{
                data.label
              }}</span>
            </div>
          </template>

          <template #cell(name)="row">
            <div class="py-1">
              <span
                class="font-weight-bold text-colorBlack m-0"
                style="font-size: 1rem"
              >
                {{ row.item.name ? row.item.name : "" }}
              </span>
            </div>
          </template>

          <template #cell(manage)="row">
            <div class="text-right">
              <img
                src="@/assets/images/icons/instructor/assignment/Delete.svg"
                img-fluid
                class="mx-1 cursor-pointer"
                width="30px"
                height="30px"
                @click="removeClass(row.item)"
              />

              <img
                src="@/assets/images/icons/instructor/assignment/Edit.svg"
                img-fluid
                width="30px"
                height="28px"
                class="cursor-pointer"
                @click="editClass(row.item)"
              />
            </div>
          </template>
        </b-table>
        <b-pagination
          size="md"
          :total-rows="totalItems"
          v-model="currentPage"
          :per-page="perPage"
        ></b-pagination>
      </b-card>
    </div>
    <CreateClassProgramModal
      @modalClosed="onModalClosed"
    />
    <EditClassProgramModal
      :classe="classe"
      @modalClosed="onModalClosed"
    />
  </div>
</template>

<script>
import CreateClassProgramModal from "@/components/uniHead/classPrograms/classProgram/CreateClassProgramModal.vue";
import EditClassProgramModal from "@/components/uniHead/classPrograms/classProgram/EditClassProgramModal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    CreateClassProgramModal,
    EditClassProgramModal,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Classes Name" },
        { key: "manage", label: "" },
      ],
      classes: [],
      classe: "",
      max: 20,
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      filter: "", // Add filter property
    };
  },
  methods: {
    ...mapActions({
      getClasses: "appData/getClasses",
      deleteClass: "appData/deleteClass",
    }),

    async fetchPaginatedData() {
      try {
        const res = await this.getClasses({
          pageNumber: this.currentPage,
          name: this.filter, // Pass filter as name property for search
        });
        this.classes = res.data.results;
        this.totalItems = res.data.count;
        this.perPage = res.data.per_page;
      } catch (error) {
        this.displayError(error);
      }
    },

    editClass(classe) {
      this.classe = classe;
      this.$nextTick(() => {
        this.$root.$emit('bv::toggle::collapse', 'edit-class-program-sidebar');
      });
    },

    async removeClass(classe) {
      this.classe = classe;

      this.$swal({
        title: "Are you sure?",
        icon: "warning",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: "Confirm",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          try {
            const res = await this.deleteClass({
              pk: this.classe.id,
            });
            if (res.status === 204) {
              this.$swal({
                title: "Class/Program deleted successfully",
                icon: "success",
              });
              if (
                (this.totalItems - 1) % this.perPage === 0 &&
                this.currentPage !== 1
              ) {
                this.currentPage -= 1;
              } else {
                await this.fetchPaginatedData();
              }
            }
          } catch (error) {
            this.show = false;
            this.displayError(error);
          }
        } else {
          this.show = false;
        }
      });
    },

    async onModalClosed() {
      await this.fetchPaginatedData();
    },

    // Clear search field
    clearSearch() {
      this.filter = "";
    },

    // Filter function for the table
    filterTable(item, filter) {
      const query = filter.toLowerCase();
      return item.name.toLowerCase().includes(query);
    },
  },
  watch: {
    currentPage: async function (val) {
      await this.fetchPaginatedData();
    },
  },
  async mounted() {
    await this.fetchPaginatedData();
  },
};
</script>

<style></style>
