<template>
  <b-sidebar
    id="edit-class-program-sidebar"
    hide-footer
    right 
    ref="editClassSidebar"
    bg-variant="white"
    backdrop
    @shown="sidebarShow()"
  >
    <div class="p-2">
      <b-form>
        <h2 class="m-0 text-colorBlack">Edit Class</h2>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">Class Name</span>
            <b-form-group class="mb-0">
              <b-form-input
                id="className"
                placeholder="Class Name"
                v-model="name"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <b-form-group>
        <b-button
          block
          type="submit"
          variant="colorBlue"
          pill
          @click="submit()"
        >
          Update
        </b-button>
      </b-form-group>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {
    classe: Object,
  },
  mixins: [util],
  data() {
    return {
      name: "",
    };
  },
  
  methods: {
    ...mapActions({
      updateClass: "appData/updateClass",
    }),

    async submit(){
      try {
        const formData = new FormData();
        formData.append('name', this.name);        
        formData.append('updated_by', this.getLoggedInUser.id); 

        const res = await this.updateClass({
          payload: formData,
          pk: this.classe.id
        });
        if (res.status === 200) {
          this.$swal({
            title: "Class/Program Updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$refs.editClassSidebar.hide();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    sidebarShow(){
      if (this.classe) {
        this.name = this.classe.name;
      }
    },
    reset (){
      this.name = "";
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style scoped></style>
