<template>
  <b-sidebar
    id="create-class-program-sidebar"
    hide-footer
    right 
    ref="createClassSidebar"
    bg-variant="white"
    backdrop
  >
    <div class="p-2">
      <b-form>
        <h2 class="m-0 text-colorBlack">Add Class</h2>
        <b-form-row class="py-1">
          <b-col>
            <span class="font-weight-bolder text-colorBlack">Class Name</span>
            <b-form-group class="mb-0">
              <b-form-input
                id="className"
                placeholder="Class Name"
                v-model="name"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>

      <b-form-group>
        <b-button
          block
          type="submit"
          variant="colorBlue"
          pill
          @click="submit()"
        >
          Add
        </b-button>
      </b-form-group>
    </div>
  </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
  },
  props: {},
  mixins: [util],
  data() {
    return {
      name: "",
    };
  },
  async mounted() {},
  methods: {
    ...mapActions({
      createClass: "appData/createClass",
    }),

    async submit() {
      try {
        const formData = new FormData();
        formData.append("name", this.name);        
        formData.append("institute", this.getLoggedInUser.institute);        
        formData.append("created_by", this.getLoggedInUser.id); 
        formData.append("updated_by", this.getLoggedInUser.id); 

        const res = await this.createClass(formData);
        if (res.status === 201) {
          this.$swal({
            title: "Class/Program created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$refs.createClassSidebar.hide();
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    reset() {
      this.name = "";      
    },

  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" })
  },
};
</script>

<style scoped></style>
